<template>
	<div class="h-full ob-t">
		<fixed-list v-show="!config.isDetailDisplay" :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam"  :isPage="!queryParam.IsNoPage">
            <div slot="buttonGroup" class="h-handle-button">
                <div class="h-b"><el-button type="primary" size="small" @click="addData" v-right-code="'LogisticsOrganization:Edit'">新增</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="deleteData" v-right-code="'LogisticsOrganization:Delete'">删除</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="enableData" v-right-code="'LogisticsOrganization:Enable'">启用</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="deactivateData" v-right-code="'LogisticsOrganization:Enable'">停用</el-button> </div>
            </div>
			<p slot="elList">
				<el-table ref="sysLogListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row
					row-key="Id"
					:tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
					<el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
					<el-table-column
						v-for="(col, index) in dataSource.ColDefs.BodyFieldParams"
						:key="index"
						:prop="col.FieldName"
						:label="col.DisplayName"
						:show-overflow-tooltip="true"
						:render-header="bindFilter(queryParam, col)"
						:fixed="index == 0 && (!config || !config.isDetailDisplay)"
						:min-width="!config || !config.isDetailDisplay ? parseInt(col.Width) : 0"
						v-if="col.Visible && (!config || !config.isDetailDisplay || (config.isDetailDisplay && index < 2))">
						<template slot-scope="scope">
							<span v-if="col.FieldName === 'Code'">
								<span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span>
							</span>
							<span v-else>{{ scope.row[col.FieldName] }}</span>
						</template>
					</el-table-column>
				</el-table>
			</p>
		</fixed-list>
	</div>
</template>

<script>
export default {
	mounted() {
		this.Event.$on(this.config.moduleEvent.initial, () => this.reloadPageList());
        this.Event.$on(this.config.moduleEvent.add, () => this.addData());
		this.onPageChange();
		this.getTreeselect();
	},
	data() {
		return {
			BodyFieldParams: [],
			queryParam: {
				PageIndex: 1,
				PageSize: 10,
				IsNoPage:true
			},
			multipleSelection: [],
			dataSource: {
				ColDefs: {},
				Result: [],
				TotalCount: 0
			},
			checked: false,
			treeExpandAll: false,
			treeNodeKey: 'Id',
			treeData: [],
			selectTreeNode: null,
			filterText: '',
			treeCheckedID: '',
			orgType: '0'
		};
	},
	props: {
		config: {
			isDetailDisplay: false,
			isButtonlDisplay: false
		},
		option: {}
	},
	watch: {
		filterText(val) {
			this.$refs.customTree.$refs.tree.filter(val);
		}
	},
	methods: {
		//刷新
		reloadPageList() {
			this.getTreeselect();
			this.onPageChange();
		},
		// 新增
		addData() {
			var _this = this;
			_this.$ajax.send('omsapi/organization/getlogempty', 'get', {}, data => {
				_this.onChangeEditDataSource(data.Result);
			});
		},
		// 编辑
		rowClick(val) {
			this.$ajax.send('omsapi/organization/getlogbyid', 'get', { id: val.Id }, data => {
				this.Event.$emit('clearEditUserForm');
				this.onChangeEditDataSource(data.Result);
			});
		},
		// 删除
		deleteData() {
			if (this.multipleSelection == null || this.multipleSelection.length == 0) {
				this.Utils.messageBox("请选择需要删除的数据", "error");
				return false;
			}
			this.$confirm('确认要删除所选数据吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => {
				var _this = this;
				var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
				_this.$ajax.send('omsapi/organization/delete?ids=' + ids, 'get', {}, result => {
					_this.reloadPageList();
					_this.Utils.messageBox("删除成功.", "success");
				});
			})
			.catch(() => {});
		},
		// 启用
		enableData() {
			if (this.multipleSelection == null || this.multipleSelection.length == 0) {
				this.Utils.messageBox("请选择需要启用的数据", "error");
				return false;
			}
			this.$confirm('确认要启用所选数据吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => {
				var _this = this;
				var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
				_this.$ajax.send('omsapi/organization/enable?ids=' + ids, 'get', {}, result => {
					_this.reloadPageList();
					_this.Utils.messageBox("启用成功.", "success");
				});
			})
			.catch(() => {});
		},
		// 停用
		deactivateData() {
			if (this.multipleSelection == null || this.multipleSelection.length == 0) {
				this.Utils.messageBox("请选择需要停用的数据", "error");
				return false;
			}
			this.$confirm('确认要停用所选数据吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => {
				var _this = this;
				var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
				_this.$ajax.send('omsapi/organization/deactivate?ids=' + ids, 'get', {}, result => {
					_this.reloadPageList();
					_this.Utils.messageBox("停用成功.", "success");
				});
			})
			.catch(() => {});
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
			this.$emit('onCheckRow', this.multipleSelection);
		},
		/* 搜索按钮点击操作 */
		search(data) {
			this.queryParam = { ...data };
			this.onPageChange();
		},
		onPageChange() {
			var _this = this;
			_this.getList();
		},
		//列表查询
		getList: function() {
			let params = this.queryParam;
			if (params.params == null || params.params == undefined) {
				params.params = {};
			}
			var me = this;
			this.$ajax.query('omsapi/organization/getloglist', 'post', params, result => {
				debugger;
				var treeList = me.Utils.handleTree(result.Result,'Id','ParentId');
				result.Result = treeList;
				this.dataSource = result;
			});
		},
		onDataSourceChange(ds) {
			this.BodyFieldParams = ds;
		},
		onChangeEditDataSource(data) {
			this.config.isDetailDisplay = true;
			this.$emit('onChangeEditDataSource', data);
		},
		//  查询左侧tree下拉树结构
		getTreeselect() {
			var _this = this;
			this.$ajax.send('omsapi/organization/getTreeList', 'get', { id: 0, type: parseInt(_this.orgType) }, data => {
				this.treeData = data.Result;
				if (this.treeData != null && this.treeData.length > 0) {
					this.treeCheckedID = this.treeData[0].Id;
					this.selectTreeNode = this.treeData[0];
				}
			});
		},
		// 选中每一个子节点---对应右边明细
		handleNodeClick(data) {
			this.selectTreeNode = data;
			this.queryParam.PageParams = {parentCode: data.Code};
			this.getList();
		},
		handleClick(tab, event) {
			this.orgType = tab.name;
			this.getTreeselect();
		}
	},
	components: {
		PubliceTree: resolve => {
			require(['./tree.vue'], resolve);
		}
	}
};
</script>

<style>
.ob-t {
	display: flex;
}

.ob-t .h-table-list {
	padding-top: 10px;
}

.organization-box {
	/* background-color: #fbfbfb; */
	border: 1px solid #e5e6eb;
	width: 250px;
	display: flex;
	flex-direction: column;
}
.menu-type {
	width: 180px;
	float: left;
}
.menu-type .el-tabs__item {
	padding: 0 15px !important;
}
.menu-add {
	width: 36px;
	height: 24px;
	line-height: 24px;
	float: right;
	text-align: center;
	border-bottom: solid 1px #e0e0e0;
	cursor: pointer;
}
.organization-box .btn-add {
	background-color: #f5f7fa;
	height: 36px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* margin: 0 4% 10px; */
	padding: 0 15px;
	color: #666;
	cursor: pointer;
}

.organization-box .btn-add i {
	font-size: 12px;
	font-weight: bold;
	color: #333;
}

.organization-box .tree-search {
	width: 92%;
	margin: 10px 4%;
}

.organization-box .scroll-bar {
	flex: 1;
	height: 0;
}

.organization-box .white-body-view {
	height: 100%;
	overflow-y: auto;
}

.organization-box .el-tree {
	background-color: transparent;
}

.organization-box .el-tree .el-tree-node__content {
	display: flex;
	flex-direction: row;
	height: 36px;
}

.organization-box .el-tree .custom-tree-node {
	font-size: 12px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-right: 10px;
}

.organization-box .el-tree .custom-tree-node * {
	font-size: inherit;
}

.organization-box .el-card__body {
	padding: 0px;

	overflow-y: auto;
}
</style>
